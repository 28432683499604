















































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';//获取专栏
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
@Component({
    components: {

    }
})
export default class personStatistics extends Vue {
    [x: string]: any;
    public form: any = {
        pageSize: 10, currentPage: 1, channelId: ''
    };
    public channelId: string = '';
    public datacreateTime: any[] = [];
    private tableData: any[] = [
    ];
    public applications: any[] = [];
    public loading: Boolean = false;
    @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
    @Inject(statisticsApi) private statisticsApi!: statisticsApi;
    public async created(): Promise<void> {
        await this.applicationsList();
        await this.getList();
    }
    public search() {
        this.getList()
    }
    // 重置
    public reset() {
        this.form = {
            pageSize: this.form.pageSize,
            currentPage: 1,
            channelId: ''
        };
        this.$refs.singleTable.clearSort();
        this.datacreateTime = [];
        this.getList();
    }
    //分页相关
    public handleSizeChange(size: number) {
        this.form.pageSize = size;
        this.getList();
    }
    public handleCurrentChange(current: number) {
        this.form.currentPage = current;
        this.getList();
    }
    //获取数据源
    public async getList() {
        var that = this;
        that.loading = true;
        if (that.datacreateTime && that.datacreateTime.length > 0) {
            that.form.censusTimeEnd = that.datacreateTime[1]
            that.form.censusTimeStart = that.datacreateTime[0]
        } else {
            delete that.form.censusTimeStart
            delete that.form.censusTimeEnd
        }
        if (that.form.channelId == '' || that.form.channelId == null) {
             delete that.form.channelId
        }
        const res = await that.statisticsApi.getPersonList(that.form);
        that.tableData = res.records;
        that.form.total = res.total;
        that.form.pages = res.pages;
        that.form.pageSize = res.size;
        that.form.currentPage = res.current;
        that.loading = false;
        if (res.records.length <= 0) {
            that.form.pages = 1;
            that.form.pageSize = 10;
            that.form.currentPage = 1;
            that.form.total = 0;
        }
    }
    //获取专栏(这里是应用)
    public async applicationsList(): Promise<void> {
        const res = await this.wechatImageTextService.applicationsList();
        this.applications = res
    }
    //导出
    public async exportClick(row: any): Promise<void> {
        var that = this, data_ = {
            "channelId": '',
            "censusTimeEnd": '',
            "censusTimeStart": ''
        }, str: any = '';
        if (that.tableData.length <= 0) {
            this.$message({
                type: 'error',
                message: '当前无下载内容！'
            })
            return;
        }
        if (that.datacreateTime && that.datacreateTime.length > 0) {
            that.form.sendTimeStart = that.datacreateTime[0];
            that.form.sendTimeEnd = that.datacreateTime[1];
            data_.censusTimeEnd = that.datacreateTime[1];
            data_.censusTimeStart = that.datacreateTime[0];
        } else {
            that.form.sendTimeStart = "";
            that.form.sendTimeEnd = "";
            delete data_.censusTimeEnd;
            delete data_.censusTimeStart;
        }
        if (that.form.channelId == '' || that.form.channelId == null) {
            delete data_.channelId
        } else {
            data_.channelId = that.form.channelId
        }
        that.getList();
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth()+1;
        var day = date.getDate();
        var timeSend = year + '-' + month + '-' + day;
        if (row == 'detail') {
            // 导出明细
        } else if (row == 'all') {
            str = that.splicing(data_)
            // 导出全部
            this.statisticsApi.personDownloadAll(str, '用户分析'+timeSend);
        }
    }
    // 导出拼接
    public splicing(data: any) {
        var str = [], strString = '';
        for (var key in data) {
            str.push(key + "=" + data[key])
        }
        strString = str.join('&')
        return strString;
    }
    // readingClick 阅读人数排序点击
    public readingClick(e: any): void {
        console.log(e.column.property)
        var that = this;
        that.form.orderName = e.column.property;
        if (e.column.order == 'descending') {
            // 向下排序
            that.form.orderType = 'desc';
        } else {
            that.form.orderType = 'asc';
        }
        // if (e.column.order == 'ascending') {
        //     // 向上排序
        // } else {
        //     // 正常排序
        // }
        that.getList();
    }

    private mounted() {
        document.onkeydown = (e) => {
            let key = (window.event as any).keyCode;
            if (key == 13) {
                this.search()
            }
        }
    }
}
